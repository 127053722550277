

















import { defineComponent, onMounted, ref } from '@vue/composition-api';
import GcAlert from '@/components/primitives/GcAlert.vue';

export default defineComponent({
  components: {
    GcAlert,
  },
  props: {
    generalInfoText: {
      default: '',
      type: String,
    },
    label: {
      default: '',
      type: String,
    },
    valid: {
      default: true,
      type: Boolean,
    },
  },
  setup() {
    const scrollMarker = ref<null | HTMLElement>(null);

    onMounted(() => {
      if (scrollMarker.value) {
        setTimeout(() => {
          const elementOffset =
            scrollMarker.value!.getBoundingClientRect().top +
            (window.pageYOffset || document.documentElement.scrollTop) -
            120;
          window.scrollTo({
            behavior: 'smooth',
            top: elementOffset,
          });
        }, 100);
      }
    });

    return { scrollMarker };
  },
});
