





















































































import { VInput } from 'vuetify/lib';
import { ref } from '@vue/composition-api';
import GcDivider from './GcDivider.vue';
import GcButton from './GcButton.vue';
import GcAlert from './GcAlert.vue';

function getSizeInMB(sizeInBytes) {
  return sizeInBytes / 1024 ** 2;
}

export default VInput.extend({
  components: {
    GcAlert,
    GcButton,
    GcDivider,
  },
  props: {
    accept: {
      default: () => '',
      type: String,
    },
    fileSizeLimit: {
      default: 2.5,
      type: [Boolean, Number],
    },
    multiple: {
      default: true,
      type: Boolean,
    },
  },
  setup(props: any, context) {
    const fileInput = ref<HTMLInputElement | null>(null);
    const dragOver = ref(false);
    const errMsg = ref('');

    const addFile = (e: any) => {
      const newFiles = e.dataTransfer?.files || e.target.files;

      if (props.fileSizeLimit) {
        const tooLargeFiles = [...newFiles].filter(
          file => getSizeInMB(file.size) > props.fileSizeLimit,
        );
        if (tooLargeFiles.length) {
          let text = `Bitte wählen Sie Dateien, deren Größe ${props.fileSizeLimit} MB nicht überschreitet.
          Die folgenden Dateien sind zu groß: `;

          text += tooLargeFiles
            .map(file => {
              return `${file.name} (${getSizeInMB(file.size).toFixed(2)} MB)`;
            })
            .join(', ');
          errMsg.value = text;
          return;
        }
      }

      if (newFiles.length) {
        const emittedValue = props.multiple
          ? [...props.value, ...newFiles]
          : [...newFiles];
        context.emit('input', emittedValue);
        errMsg.value = '';
      }
    };
    const removeFile = (fileIndex: number) => {
      if (props.value[fileIndex]) {
        const updatedArray = [...props.value];
        updatedArray.splice(fileIndex, 1);
        context.emit('input', updatedArray);
      }
    };
    const openFileChooser = () => {
      fileInput.value?.click();
      errMsg.value = '';
    };
    const onDrop = (e: any) => {
      dragOver.value = false;
      addFile(e);
    };
    const onDragOver = () => {
      dragOver.value = true;
    };
    const onDragLeave = () => {
      dragOver.value = false;
    };

    const getFileIcon = (file: File) => {
      if (file.type?.includes('pdf')) {
        return ['fad', 'file-pdf'];
      } else if (file.type?.includes('image/')) {
        return ['fad', 'file-image'];
      } else {
        return ['fad', 'file-alt'];
      }
    };

    return {
      addFile,
      dragOver,
      errMsg,
      fileInput,
      getFileIcon,
      onDragLeave,
      onDragOver,
      onDrop,
      openFileChooser,
      removeFile,
    };
  },
});
