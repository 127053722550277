import { StepId } from '@/consts/assistant-steps';
import {
  FormMultipleChoiceConfig,
  StepDefinition,
} from '@/interfaces/step-definition';
import component from './Misc.vue';
import { FormFieldType } from '@/consts/input-types';

const config: StepDefinition = {
  component,
  fields: [
    {
      config: {
        options: [
          {
            value: 'personalVisit',
          },
          {
            value: 'adInternet',
          },
          {
            value: 'adFacebook',
          },
          {
            value: 'recommendationFriends',
          },
          {
            value: 'ads',
          },
          {
            value: 'recommendationHandicraft',
          },
          {
            value: 'estore',
          },
          {
            value: 'tradeFair',
          },
          {
            value: 'salesperson',
          },
          {
            value: 'invoiceLeaflet',
          },
        ],
        singleAnswer: true,
      } as FormMultipleChoiceConfig,
      name: 'referrer',
      required: false,
      type: FormFieldType.MultipleChoice,
    },
  ],
  id: StepId.MISC,
};

export default config;
