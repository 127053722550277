const calculatePrice = (basePrice, data) => {
  let total = basePrice;
  const lineItems = [
    {
      label: 'Grund&shy;preis ab',
      suffix: '*',
      value: basePrice,
    },
  ];

  // @todo: Müssen wir gucken wie wir das "gewünscht" abbilden
  // if (???) {
  //   lineItems.push({
  //     label: 'Zirkulationspumpe',
  //     value: 2.71,
  //   });
  // total += 2.71;
  // }

  if (data.household.distribution && data.household.distribution.length === 2) {
    lineItems.push({
      label: '2. Heiz&shy;kreis',
      suffix: '',
      value: 12.59,
    });
    total += 12.59;
  }

  if (
    !data.contact.zipcode.match(
      /(52062|52064|52066|52068|52070|52072|52074|52076|52078|52080)/,
    )
  ) {
    lineItems.push({
      label: 'Wasser&shy;auf&shy;bereitungs&shy;modul',
      suffix: '',
      value: 7,
    });
    total += 7;
  }

  return {
    lineItems,
    total,
  };
};

export default calculatePrice;
