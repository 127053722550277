import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faCalendarAlt,
  faCalendarWeek,
  faCaretDown,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faChevronLeft,
  faChevronRight,
  faClock,
  faEdit,
  faExclamation,
  faExclamationTriangle,
  faFileUpload,
  faInfoCircle,
  faMinus,
  faPaperclip,
  faPencilAlt,
  faPlus,
  faQuestion,
  faSearch,
  faTimes,
  faTimesCircle,
} from '@fortawesome/pro-solid-svg-icons';
import {
  faCreditCard,
  faFile,
  faFileAlt,
  faFileImage,
  faFilePdf,
  faFilePlus,
  faIceCream,
  faMoneyBillAlt,
  faUpload,
  faUserHeadset,
} from '@fortawesome/pro-duotone-svg-icons';
import { faHomeLg, faSquare } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(
  faAngleDown,
  faAngleUp,
  faAngleLeft,
  faAngleRight,
  faCheck,
  faCheckCircle,
  faEdit,
  faFileUpload,
  faMinus,
  faPaperclip,
  faPencilAlt,
  faPlus,
  faSearch,
  faTimesCircle,
  faTimes,
  faCheckSquare,
  faInfoCircle,
  faExclamationTriangle,
  faQuestion,
  faCaretDown,
  faChevronLeft,
  faChevronRight,
  faCalendarAlt,
  faCalendarWeek,
  faClock,
  faExclamation,
  faIceCream,
  faCreditCard,
  faMoneyBillAlt,
  faCheckCircle,
  faUpload,
  faFilePlus,
  faFileAlt,
  faFilePdf,
  faFileImage,
  faSquare,
  faUserHeadset,
  faFile,
  faHomeLg,
);

Vue.component('font-awesome-icon', FontAwesomeIcon);
