import axios from 'axios';
import { AssistantContext } from '@/config/context';
import { StepDefinition } from '@/interfaces/step-definition';
import { getMailBody, productRequestData } from '../formatter/context';
import { getMailAttachments } from './uploadFile';

export async function submit(
  steps: StepDefinition[],
  context: AssistantContext,
): Promise<void> {
  const mailBody = getMailBody(steps, context);
  const attachments = await getMailAttachments(context);
  const productRequest = productRequestData(mailBody, context);

  try {
    await axios.post(process.env.VUE_APP_FUNCTIONS_URL + '/submit', {
      attachments,
      mailBody,
      productRequest,
    });
  } catch (e) {
    console.error(e);
    throw new Error('Es ist ein Fehler beim Abschicken der Daten aufgetreten.');
  }
}
