
































































































import GcCol from '../../components/primitives/GcCol.vue';
import GcForm from '../../components/primitives/GcForm.vue';
import GcRow from '../../components/primitives/GcRow.vue';
import AssistantStep from '../../components/AssistantStep.vue';
import AssistantStepNavigation from '../../components/AssistantStepNavigation.vue';
import { defineComponent, ref } from '@vue/composition-api';
import { stepComponentProps } from '@/lib/steps/helper';
import { useBaseTransitions } from '@/lib/xstate/transitions';
import { getStepContext } from '@/lib/context';
import FormField from '../../components/FormField.vue';
import MultipleChoice from '@/components/MultipleChoice.vue';
import completedSteps from '../../lib/completed-steps';
import { submit } from '@/lib/submit/submit';
import { StepDefinition } from '@/interfaces/step-definition';
import i18n from '@/plugins/i18n';
import { sendEvent } from '@/lib/analytics/dataLayer';

export default defineComponent({
  components: {
    AssistantStep,
    AssistantStepNavigation,
    FormField,
    GcCol,
    GcForm,
    GcRow,
    MultipleChoice,
  },
  props: {
    ...stepComponentProps,
  },
  setup(props, { emit }) {
    const { onNext, onPrev } = useBaseTransitions(emit);
    const formData = getStepContext(
      props.assistantContext,
      props.step as StepDefinition,
    );
    const submitting = ref(false);
    const submitError = ref<boolean | string>(false);

    async function onSubmit() {
      if (
        process.env.VUE_APP_GA_EVENT_NAME &&
        process.env.VUE_APP_GA_EVENT_ACTION &&
        process.env.VUE_APP_GA_EVENT_CATEGORY &&
        process.env.VUE_APP_GA_EVENT_LABEL
      ) {
        sendEvent({
          eventAction: process.env.VUE_APP_GA_EVENT_ACTION,
          eventCategory: process.env.VUE_APP_GA_EVENT_CATEGORY,
          eventLabel: process.env.VUE_APP_GA_EVENT_LABEL,
          eventName: process.env.VUE_APP_GA_EVENT_NAME,
        });
      }

      submitError.value = false;
      submitting.value = true;
      try {
        await submit(props.steps as StepDefinition[], props.assistantContext);
        onNext();
      } catch (e) {
        submitError.value = e.message || i18n.t('message.error').toString();
      } finally {
        submitting.value = false;
      }
    }

    return {
      completedSteps,
      formData,
      onNext,
      onPrev,
      onSubmit,
      submitError,
      submitting,
    };
  },
});
