














































import { PropType, defineComponent } from '@vue/composition-api';
import GcDivider from '../components/primitives/GcDivider.vue';
import { StepDefinition } from '../interfaces/step-definition';
import { AssistantContext } from '../config/context';
import completedSteps from '../lib/completed-steps';

export default defineComponent({
  components: {
    GcDivider,
  },
  props: {
    context: {
      required: true,
      type: Object as PropType<AssistantContext>,
    },
    currentStep: {
      required: true,
      type: Number,
    },
    done: {
      default: false,
      type: Boolean,
    },
    showLabels: {
      default: false,
      type: Boolean,
    },
    steps: {
      required: true,
      type: Array as () => Array<StepDefinition>,
    },
  },
  setup(props, { emit }) {
    function checkVisibility(step: StepDefinition) {
      return step.visiblityCondition
        ? step.visiblityCondition(props.context)
        : true;
    }

    function backToStep(step: StepDefinition) {
      if (!checkVisibility(step) || props.done) {
        return;
      }
      emit('transition', {
        event: `BACK_TO_${step.id.toUpperCase()}`,
      });
    }

    function forwardToStep(step: StepDefinition) {
      emit('transition', {
        event: `FORWARD_TO_${step.id.toUpperCase()}`,
      });
    }

    function toStep(
      stepIdx: number,
      curStepIdx: number,
      stepId: StepDefinition,
    ) {
      if (curStepIdx > stepIdx + 1) {
        backToStep(stepId);
      } else if (curStepIdx < stepIdx + 1) {
        if (completedSteps[stepId.id]) {
          forwardToStep(stepId);
        }
      }
    }

    return {
      backToStep,
      checkVisibility,
      completedSteps,
      toStep,
    };
  },
});
