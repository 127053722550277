import { StepId } from '@/consts/assistant-steps';
import {
  FormMultipleChoiceConfig,
  StepDefinition,
} from '@/interfaces/step-definition';
import component from './Heating.vue';
import { FormFieldType } from '@/consts/input-types';

const config: StepDefinition = {
  component,
  fields: [
    {
      config: {
        columns: 2,
        options: [
          {
            value: 'gas',
          },
          {
            value: 'oil',
          },
          {
            value: 'electricity',
          },
          {
            value: 'other',
          },
        ],
        singleAnswer: true,
      } as FormMultipleChoiceConfig,
      name: 'currentHeating',
      required: false,
      type: FormFieldType.MultipleChoice,
    },
  ],
  id: StepId.HEATING,
};

export default config;
