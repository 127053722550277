


















import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  setup(_, { emit }) {
    function onClickSkip() {
      emit('skip');
    }

    return { onClickSkip };
  },
});
