import { StepId } from '@/consts/assistant-steps';
import { FormFieldType } from '@/consts/input-types';
import { ValidationType } from '@/consts/validation-types';
import { StepDefinition } from '@/interfaces/step-definition';
import component from './Contact.vue';

const config: StepDefinition = {
  component,
  fields: [
    {
      name: 'firstname',
      required: true,
      type: FormFieldType.TextField,
    },
    {
      name: 'lastname',
      required: true,
      type: FormFieldType.TextField,
    },
    {
      name: 'streetAddress',
      required: true,
      type: FormFieldType.TextField,
    },
    {
      name: 'zipcode',
      required: true,
      type: FormFieldType.TextField,
    },
    {
      name: 'city',
      required: true,
      type: FormFieldType.TextField,
    },
    {
      name: 'phone',
      required: true,
      type: FormFieldType.TextField,
      validation: [ValidationType.phoneNumber],
    },
    {
      name: 'email',
      required: true,
      type: FormFieldType.TextField,
    },
    {
      name: 'agreement',
      required: true,
      type: FormFieldType.Checkbox,
    },
  ],
  id: StepId.CONTACT,
  isContactForm: true,
  transitions: {
    JUMP_TO_DONE: {
      target: StepId.PRODUCTS,
    },
  },
};

export default config;
