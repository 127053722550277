import { StepId } from '@/consts/assistant-steps';
import {
  FormMultipleChoiceConfig,
  FormSliderConfig,
  FormTextFieldConfig,
  StepDefinition,
} from '@/interfaces/step-definition';
import component from './Object.vue';
import { FormFieldType } from '@/consts/input-types';

const config: StepDefinition = {
  component,
  fields: [
    {
      config: {
        max: new Date().getFullYear(),
        min: 1980,
        thumbLabel: 'always',
      } as FormSliderConfig,
      name: 'constructionYear',
      outputValueFormatter: value => {
        if (value === 1980) {
          return '1980 oder früher';
        }

        return value;
      },
      required: false,
      type: FormFieldType.Slider,
    },
    {
      config: {
        columns: 2,
        options: [
          {
            value: 'singleFamily',
          },
          {
            value: 'multiFamily',
          },
          {
            value: 'apartment',
          },
          {
            value: 'other',
          },
        ],
        singleAnswer: true,
      } as FormMultipleChoiceConfig,
      name: 'buildingType',
      required: true,
      type: FormFieldType.MultipleChoice,
    },
    {
      config: {
        min: 1,
        suffix: 'm²',
        type: 'number',
      } as FormTextFieldConfig,
      name: 'heatingArea',
      required: false,
      type: FormFieldType.TextField,
    },
  ],
  id: StepId.OBJECT,
};

export default config;
