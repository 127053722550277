import { StepId } from '@/consts/assistant-steps';
import {
  FormMultipleChoiceConfig,
  StepDefinition,
} from '@/interfaces/step-definition';
import component from './Household.vue';
import { FormFieldType } from '@/consts/input-types';

const config: StepDefinition = {
  component,
  fields: [
    {
      config: {
        options: [
          {
            value: 'heatHotwater',
          },
          {
            value: 'spaceHeating',
          },
        ],
        singleAnswer: true,
      } as FormMultipleChoiceConfig,
      name: 'currentUse',
      required: true,
      type: FormFieldType.MultipleChoice,
    },
    {
      config: {
        columns: 2,
        options: [
          {
            value: 'radiator',
          },
          {
            value: 'underfloorHeating',
          },
        ],
      } as FormMultipleChoiceConfig,
      name: 'distribution',
      required: false,
      type: FormFieldType.MultipleChoice,
    },
  ],
  id: StepId.HOUSEHOLD,
};

export default config;
