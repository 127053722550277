




import { defineComponent } from '@vue/composition-api';
import GcAlert from '../../components/primitives/GcAlert.vue';
import { stepComponentProps } from '@/lib/steps/helper';

export default defineComponent({
  components: {
    GcAlert,
  },
  props: {
    ...stepComponentProps,
  },
});
