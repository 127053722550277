import { reactive, watch } from '@vue/composition-api';
import { StepId } from '../consts/assistant-steps';

const COMPLETED_STEPS_KEY = 'assistent-completed-steps-key';

const stepsMap = {};
for (const id in StepId) {
  stepsMap[StepId[id]] = false;
}

const reactiveStepsMap = reactive(stepsMap);

watch(reactiveStepsMap, reactiveStepsMap => {
  localStorage.setItem(COMPLETED_STEPS_KEY, JSON.stringify(reactiveStepsMap));
});

export function restoreCompletedSteps() {
  if (localStorage) {
    const stepsStr = localStorage.getItem(COMPLETED_STEPS_KEY);
    if (stepsStr) {
      const storedSteps = JSON.parse(stepsStr);
      for (const key in storedSteps) {
        reactiveStepsMap[key] = storedSteps[key];
      }
    }
  }
}

export function removeCompletedSteps() {
  localStorage.removeItem(COMPLETED_STEPS_KEY);
}

export default reactiveStepsMap;
