import { StepId } from '@/consts/assistant-steps';
import {
  FormMultipleChoiceConfig,
  StepDefinition,
} from '@/interfaces/step-definition';
import component from './Products.vue';
import { FormFieldType } from '@/consts/input-types';
import i18n from '@/plugins/i18n';

const config: StepDefinition = {
  component,
  fields: [
    {
      config: {
        options: [],
      } as FormMultipleChoiceConfig,
      name: 'suggestedProducts',
      outputFormatter: products => {
        const label = `${i18n.t(
          `${StepId.PRODUCTS}.formFields.suggestedProducts.label`,
        )}`;

        const productSuggestions = products
          .map(product => product.name)
          .filter(name => typeof name === 'string' && name.trim())
          .join(', ');

        return `**${label}:** ${productSuggestions}`;
      },
      required: false,
      type: FormFieldType.MultipleChoice,
    },
  ],
  id: StepId.PRODUCTS,
};

export default config;
