import calculatePrice from '@/steps/products/calculatePrice';

const productData = (product: string, data) => {
  const baseData = {
    Basis: {
      basePrice: 119,
      details: [
        'Automatische Verbrennungs&shy;optimierung',
        'Sparsam und effizient',
        'Ein Kessel für fast jeden Bedarf',
        'Kompatibel mit allen Gas&shy;arten',
        'Heiz&shy;leistung von 2,9 bis 20 kW',
      ],
      download: {
        '/pdf/broetje-gasheizung-wgb-evo-produktflyer.pdf': 'Produktflyer',
      },
      fuel: 'Gas',
      group: 'Standardlösungen mit Erdgas',
      technology:
        'Brötje Gas-Brenn&shy;wert&shy;wand&shy;kessel WGB EVO 20i ohne Warm&shy;wasser&shy;bereitung',
    },
    Hybrid: {
      basePrice: 342,
      details: [
        'Immer günstigste Betriebs&shy;weise dank innovativer Hybrid&shy;regelung',
        'Wärme&shy;pumpe ohne Heiz&shy;stab erzeugt den Groß&shy;teil der Heiz&shy;energie',
        'Beistell&shy;speicher mit einem leistungs&shy;starken Wärme&shy;tauscher',
        'Kompatibel mit allen Gas&shy;arten',
        'förderfähig',
      ],
      download: {
        '/pdf/broetje-waermepumpen-blw-mono-p-produktflyer.pdf':
          'Produktflyer Wärmepumpe',
        '/pdf/flyer-lsr-eas-bs-psw.pdf': 'Produktflyer Wasserspeicher',
        '/pdf/produktflyer-wls-wlc.pdf': 'Produktflyer Kessel',
      },
      fuel: 'Gas',
      group: 'Förderfähige Lösungen mit erneuerbarer Wärme',
      technology:
        'Brötje Luft-Wasser-Wärme&shy;pumpe BLW Mono-P 8 + Gas-Brenn&shy;wert&shy;therme WLS 24 + Warm&shy;wasser&shy;speicher EAS',
    },
    Kombi: {
      basePrice: 124,
      details: [
        'Ideale Lösung für Etagen&shy;wohnungen',
        'Leistungs&shy;stark und dabei sparsam',
        'Ideale Lösung für Etagen&shy;wohnungen',
        'Heizl&shy;eistung von 3,5 bis 22/24 kW',
        'Zuverlässige und sehr leise Wärme&shy;ver&shy;sorgung',
      ],
      download: {
        '/pdf/broetje-gasheizung-wgb-u-wgb-c-produktflyer.pdf': 'Produktflyer',
      },
      fuel: 'Gas',
      group: 'Standardlösungen mit Erdgas',
      technology:
        'Brötje Gas-Brenn&shy;wert&shy;wand&shy;kessel WGB-C 20/24i mit Warm&shy;wasser&shy;bereitung',
    },
    Komfort: {
      basePrice: 139,
      details: [
        'Hoher Warm&shy;wasser&shy;komfort',
        'Separater Trink&shy;wasser&shy;speicher',
        'Kompatibel mit allen Gas&shy;arten',
        'Wirt&shy;schaft&shy;licher Allrounder für Ein- und Mehr&shy;familien&shy;häuser',
      ],
      download: {
        '/pdf/broetje-gasheizung-wgb-evo-produktflyer.pdf':
          'Produktflyer Kessel',
        '/pdf/flyer-lsr-eas-bs-psw.pdf': 'Produktflyer Wasserspeicher',
      },
      fuel: 'Gas',
      group: 'Standardlösungen mit Erdgas',
      technology:
        'Brötje Gas-Brenn&shy;wert&shy;wand&shy;kessel WGB EVO 20i + Trink&shy;wasser&shy;speicher bis 160 Liter',
    },
    Umwelt: {
      basePrice: 270,
      details: [
        'Ökologische Art Heiz&shy;energie zu erzeugen',
        'Hohe Leistungs&shy;zahl der Wärme&shy;pumpe',
        'Wärme&shy;pumpe mit Heiz&shy;stab zur Ab&shy;deck&shy;ung der Hoch&shy;last&shy;zeiten',
        'förder&shy;fähig',
      ],
      download: {
        '/pdf/broetje-waermepumpen-blw-mono-p-produktflyer.pdf':
          'Produktflyer Wärmepumpe',
        '/pdf/flyer-lsr-eas-bs-psw.pdf': 'Produktflyer Wasserspeicher',
      },
      fuel: 'Gas',
      group: 'Förderfähige Lösungen mit erneuerbarer Wärme',
      technology:
        'Brötje Luft-Wasser-Wärme&shy;pumpe BLW Mono-P 8 + Warm&shy;wasser&shy;speicher EAS-W 300 B',
    },
  };

  return {
    ...baseData[product],
    name: product,
    price: calculatePrice(baseData[product].basePrice, data),
  };
};

export default productData;
