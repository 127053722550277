export enum StepId {
  HEATING = 'heating',
  HOUSEHOLD = 'household',
  OBJECT = 'object',
  CONTACT = 'contact',
  MISC = 'misc',
  PRODUCTS = 'products',
  DONE = 'done',
}

export enum StepGroupId {
  LOCATIONS = 'locations',
}
