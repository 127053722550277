export default {
  contact: {
    formFields: {
      agreement: {
        label:
          'Hiermit stimme ich der <a href="#" target="_blank">Datenschutzerklärung</a> zu.',
        title: 'Zustimmung Datenschutz',
      },
      city: {
        label: 'Stadt',
      },
      email: {
        label: 'E-Mail-Adresse',
      },
      firstname: {
        label: 'Vorname',
      },
      lastname: {
        label: 'Nachname',
      },
      phone: {
        label: 'Telefonnummer',
      },
      streetAddress: {
        label: 'Straße und Hausnummer',
      },
      zipcode: {
        label: 'Postleitzahl',
      },
    },
    label: 'Kontaktformular',
    submitButtonText: 'Absenden',
    title: 'Kontakt',
  },
  done: {
    title: 'Fertig',
  },
  general: {
    newHouseFieldsInfo:
      'Bei einem Neubau beantworten Sie bitte nur die zutreffenden Fragen.',
  },
  heating: {
    formFields: {
      currentHeating: {
        label: 'Womit heizen Sie aktuell?',
        options: {
          electricity: {
            label: 'Strom',
          },
          gas: {
            label: 'Gas',
          },
          oil: {
            label: 'Öl',
          },
          other: {
            label: 'Sonstiges',
          },
        },
        title: 'Aktueller Brennstoff',
      },
    },
    label: 'Heizung',
    title: 'Heizung',
  },
  household: {
    formFields: {
      currentUse: {
        label: 'Wie wird die Heizung aktuell genutzt?',
        options: {
          heatHotwater: {
            label: 'Raumwärme und Warmwasser',
          },
          spaceHeating: {
            label: 'Nur Raumwärme',
          },
        },
        title: 'Nutzung',
      },
      distribution: {
        label: 'Welchen Wärmeverteiler haben Sie?',
        options: {
          radiator: {
            label: 'Heizkörper',
          },
          underfloorHeating: {
            label: 'Fußbodenheizung',
          },
        },
        title: 'Wärmeverteiler',
      },
    },
    label: 'Haushalt',
    title: 'Haushalt',
  },
  misc: {
    formFields: {
      referrer: {
        label: 'Wie sind Sie auf uns aufmerksam geworden?',
        options: {
          adFacebook: {
            label: 'Werbung auf Facebook',
          },
          adInternet: {
            label: 'Werbung im Internet',
          },
          ads: {
            label: 'Anzeigen (z.B. Zeitung)',
          },
          estore: {
            label: 'E-Store',
          },
          invoiceLeaflet: {
            label: 'Rechnungsbeileger',
          },
          personalVisit: {
            label: 'Persönlicher Besuch',
          },
          recommendationFriends: {
            label: 'Empfehlung durch Bekannte',
          },
          recommendationHandicraft: {
            label: 'Empfehlung durch Handwerksbetrieb',
          },
          salesperson: {
            label: 'Ansprache durch Akquisieur',
          },
          tradeFair: {
            label: 'Messen / Veranstaltungen',
          },
        },
        title: 'Aufmerksam geworden durch',
      },
    },
    label: 'Sonstiges',
    title: 'Sonstiges',
  },
  object: {
    formFields: {
      buildingType: {
        label: 'Um was für ein Gebäude handelt es sich?',
        options: {
          apartment: {
            label: 'Etagenwohnung',
          },
          multiFamily: {
            label: 'Mehrfamilienhaus',
          },
          other: {
            label: 'Sonstiges',
          },
          singleFamily: {
            label: 'Einfamilienhaus',
          },
        },
        title: 'Gebäudetyp',
      },
      constructionYear: {
        customOption: 'Genaues Jahr',
        label:
          'Aus welchem Baujahr ist das Gebäude... oder wann fand die letzte energetische Modernisierung statt?',
        options: {
          '1980': {
            label: 'von / vor 1980',
          },
          '2000': {
            label: 'um 2000',
          },
          today: {
            label: 'bis heute',
          },
        },
        title: 'Baujahr',
      },
      heatingArea: {
        label: 'Geben Sie die zu beheizende Fläche an',
        title: 'Heizfläche',
      },
      levels: {
        label: 'Wie viele Etagen hat das Gebäude?',
        title: 'Etagen',
      },
    },
    label: 'Objekt',
    title: 'Objekt',
  },
  products: {
    formFields: {
      suggestedProducts: {
        label: 'Vorgeschlagene Produkte',
      },
    },
    label: 'Ihr persönliches Wärme Plus-Angebot',
    title: 'Produkte',
  },
  start: {
    buttonText: 'Assistent starten',
    description: {
      saturation: 'Liebe Wärme-Interessentin, lieber Wärme-Interessent,',
      text:
        'bitte beantworten Sie folgend ein paar Fragen. Passend zu Ihren Anforderungen und Gegebenheiten in Ihrem Objekt werden Ihnen zum Schluss Vorschläge für eine neue Heizung angezeigt.',
    },
    label: 'STAWAG Wärme Plus',
    title: 'Start',
  },
};
