import axios from 'axios';

export type ContextByProxy = {
  token: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  street: string | null;
  zipcode: string | null;
  city: string | null;
  country: string | null;
  phoneNumber: string | null;
};

export const ContextByProxy = (): Promise<ContextByProxy | null> => {
  const authorization = process.env.VUE_APP_SHOP_BASIC_AUTH
    ? { authorization: `Basic ${process.env.VUE_APP_SHOP_BASIC_AUTH}` }
    : {};

  const withCredentials =
    location.href.indexOf(process.env.VUE_APP_SHOP_URL) !== 0
      ? { withCredentials: true }
      : {};

  return new Promise((resolve, reject) => {
    axios
      .get(
        `${process.env.VUE_APP_SHOP_URL}/_proxy/store-api?path=/store-api/context`,
        {
          headers: {
            ...authorization,
          },
          ...withCredentials,
        },
      )
      .then(response => {
        if (response.status !== 200) {
          console.log('no status 200');
          reject();
          return;
        }

        if (!response.data || !response.data.customer) {
          console.log('no customer data');
          resolve(null);
          return;
        }

        resolve({
          city: response.data.customer.defaultBillingAddress.city ?? null,
          country:
            response.data.customer.defaultBillingAddress.country.name ?? null,
          email: response.data.customer.email ?? null,
          firstName: response.data.customer.firstName ?? null,
          lastName: response.data.customer.lastName ?? null,
          phoneNumber:
            response.data.customer.defaultBillingAddress.phoneNumber ?? null,
          street: response.data.customer.defaultBillingAddress.street ?? null,
          token: response.data.token ?? null,
          zipcode: response.data.customer.defaultBillingAddress.zipcode ?? null,
        } as ContextByProxy);
      })
      .catch(error => {
        console.error(error);
        reject();
      });
  });
};
