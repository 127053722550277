





























import { defineComponent, ref } from '@vue/composition-api';
import {
  VBtn,
  VCard,
  VCardActions,
  VCardText,
  VCardTitle,
  VDialog,
} from 'vuetify/lib';
import GcAlert from './primitives/GcAlert.vue';

export default defineComponent({
  components: {
    GcAlert,
    VBtn,
    VCard,
    VCardActions,
    VCardText,
    VCardTitle,
    VDialog,
  },
  props: {
    closeBtnText: {
      default: 'Schließen',
      type: String,
    },
    title: {
      default: '',
      type: String,
    },
  },
  setup() {
    const isOpen = ref(false);
    return {
      isOpen,
    };
  },
});
