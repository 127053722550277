const productFinder = (data): string[] => {
  const products: string[] = [];
  if (data.object.buildingType && data.household.currentUse) {
    const hasGasConnection =
      data.heating.currentHeating &&
      data.heating.currentHeating.includes('gas');

    // Basis
    if (
      (data.object.buildingType.includes('singleFamily') ||
        data.object.buildingType.includes('apartment')) &&
      hasGasConnection &&
      data.household.currentUse.includes('spaceHeating')
    ) {
      products.push('Basis');
    }

    // Kombi
    if (
      (data.object.buildingType.includes('singleFamily') ||
        data.object.buildingType.includes('apartment')) &&
      hasGasConnection &&
      data.household.currentUse.includes('heatHotwater')
    ) {
      products.push('Kombi');
    }

    // Komfort, Duo
    if (
      (data.object.buildingType.includes('singleFamily') ||
        data.object.buildingType.includes('multiFamily')) &&
      hasGasConnection &&
      data.household.currentUse.includes('heatHotwater')
    ) {
      products.push('Komfort');
    }

    // Solar, Hybrid
    if (
      data.object.buildingType.includes('singleFamily') &&
      data.household.currentUse.includes('heatHotwater')
    ) {
      products.push('Hybrid');
    }

    // Umwelt
    if (
      data.object.buildingType.includes('singleFamily') &&
      data.household.currentUse.includes('heatHotwater') &&
      data.object.constructionYear >= 1996
    ) {
      products.push('Umwelt');
    }
  }

  if (
    !products.includes('Komfort') &&
    (products.includes('Hybrid') || products.includes('Umwelt'))
  ) {
    products.push('Komfort');
  }

  // debug only
  // return [
  //   'Basis',
  //   'Kombi',
  //   'Komfort',
  //   'Hybrid',
  //   'Umwelt',
  // ];
  return products;
};

export default productFinder;
