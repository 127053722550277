















































import { PropType, computed, defineComponent, ref } from '@vue/composition-api';
import SkipMessage from './SkipMessage.vue';
import { JUMP_TO_CONTACTFORM, PREV } from '../consts/assistant-state-events';
import GcProgressCircular from '../components/primitives/GcProgressCircular.vue';
import AssistantStepsOverview from '../components/AssistantStepsOverview.vue';
import AssistantHeader from '../components/AssistantHeader.vue';
import StartScreen from '../components/StartScreen.vue';
import { getContactFormStep, getStepIndex } from '../lib/steps/helper';
import { StepDefinition } from '../interfaces/step-definition';
import { AssistantMachine } from '../interfaces/assistant';
import RestoreAlert from './RestoreAlert.vue';
import { restoreCompletedSteps } from '../lib/completed-steps';
import { ContextByProxy } from "@/lib/portal/context-by-proxy";

export default defineComponent({
  components: {
    AssistantHeader,
    AssistantStepsOverview,
    GcProgressCircular,
    RestoreAlert,
    SkipMessage,
    StartScreen,
  },
  props: {
    machine: {
      required: true,
      type: Object as PropType<AssistantMachine>,
    },
    steps: {
      required: true,
      type: Array as PropType<StepDefinition[]>,
    },
  },
  setup(props) {
    const { final, send, currentStepIndex } = props.machine;
    const assistantStarted = ref(false);
    const stepTransition = ref('slide-fade');

    ContextByProxy().then(customer => {
      if (customer && props.machine.context.contact) {
        const contact = props.machine.context.contact;
        if (!contact.firstName) {
          contact.firstname = customer.firstName;
        }
        if (!contact.lastname) {
          contact.lastname = customer.lastName;
        }
        if (!contact.streetAddress) {
          contact.streetAddress = customer.street;
        }
        if (!contact.zipcode) {
          contact.zipcode = customer.zipcode;
        }
        if (!contact.city) {
          contact.city = customer.city;
        }
        if (!contact.email) {
          contact.email = customer.email;
        }
        if (!contact.phone) {
          contact.phone = customer.phoneNumber;
        }
      }
    });

    const jumpToContactForm = () => {
      send(JUMP_TO_CONTACTFORM);
    };

    const showSkipMessage = computed(() => {
      const contactFormStep = getContactFormStep(props.steps);
      if (contactFormStep) {
        const contactFormStepIndex = getStepIndex(contactFormStep, props.steps);
        return currentStepIndex.value - 1 < contactFormStepIndex;
      }

      return false;
    });

    const onTransition = (transition: { event: string; payload?: any }) => {
      stepTransition.value =
        transition.event === PREV ? 'slide-fade-reverse' : 'slide-fade';
      send(transition.event, transition.payload);
    };

    const overviewSteps = props.steps.map(step => step?.id);

    return {
      assistantStarted,
      currentStepIndex,
      final,
      jumpToContactForm,
      onTransition,
      overviewSteps,
      restoreCompletedSteps,
      showSkipMessage,
      stepTransition,
    };
  },
});
