










































import { defineComponent } from '@vue/composition-api';
import GcButton from '../components/primitives/GcButton.vue';
import GcAlert from '../components/primitives/GcAlert.vue';

export default defineComponent({
  components: {
    GcAlert,
    GcButton,
  },
  props: {
    isSubmit: {
      default: false,
      type: Boolean,
    },
    showBackButton: {
      default: true,
      type: Boolean,
    },
    submitError: {
      default: false,
      type: [Boolean, String],
    },
    submitting: {
      default: false,
      type: Boolean,
    },
    valid: {
      default: true,
      type: Boolean,
    },
  },
  setup(_, { emit }) {
    function next() {
      emit('next');
    }

    function prev() {
      emit('prev');
    }

    function submit() {
      emit('submit');
    }

    return {
      next,
      prev,
      submit,
    };
  },
});
