var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gc-flex gc-justify-between gc-relative gc-mb-10"},[_c('div',{staticClass:"gc-absolute gc-left-0 gc-w-full gc-top-3 gc-bg-gray-100 gc-h-px"}),_vm._l((_vm.steps),function(step,n){return _c('a',{key:(n + "-step"),staticClass:"transition-opacity duration-150 ease-in-out gc-flex gc-justify-center gc-items-center gc-flex-col gc-z-10 gc-cursor-default",class:{
      'enable-click gc-cursor-pointer':
        _vm.completedSteps[step.id] ||
        (n < _vm.currentStep - 1 && _vm.checkVisibility(step) && !_vm.done),
      'gc-opacity-25': !_vm.checkVisibility(step),
    },attrs:{"step":n + 1,"edit-icon":"$vuetify.icons.edit","data-test-id":"steps-overview-item"},on:{"click":function($event){$event.preventDefault();return _vm.toStep(n, _vm.currentStep, step)}}},[_c('i',{staticClass:"gc-flex gc-items-center gc-justify-center gc-w-6 gc-h-6 gc-rounded-full gc-text-xs gc-not-italic gc-mb-2",class:[
        _vm.currentStep === n + 1
          ? 'gc-bg-primary gc-text-white'
          : 'gc-bg-gray-200' ]},[(!_vm.completedSteps[step.id] || _vm.currentStep === n + 1)?_c('span',[_vm._v(_vm._s(n + 1))]):_c('font-awesome-icon',{staticClass:"gc-text-primary",attrs:{"icon":['fas', 'check']}})],1),(_vm.showLabels)?_c('span',{staticClass:"gc-hidden md:gc-block gc-text-xs",class:{ 'gc-font-bold': _vm.currentStep === n + 1 }},[_vm._v(_vm._s(_vm.$t(((step.id) + ".title"))))]):_vm._e()])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }