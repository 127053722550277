import { FormFieldType } from '@/consts/input-types';
import { ValidationType } from '@/consts/validation-types';
import {
  FormField,
  FormUploadFieldConfig,
  Validator,
} from '@/interfaces/step-definition';
import i18n from '../../plugins/i18n';

export const getValidators = (formField: FormField): Array<unknown> => {
  const validators: Array<unknown> = [];

  if (formField.required) {
    if (
      formField.type === FormFieldType.MultipleChoice ||
      (formField.type === FormFieldType.FileUpload &&
        (formField.config as FormUploadFieldConfig).multiple)
    ) {
      const checkRequired = (v: Array<any>) => {
        return v.length > 0 || `Dies ist ein Plichtfeld`;
      };
      validators.push(checkRequired);
    } else {
      const checkRequired = (v: any) => !!v || `Dies ist ein Plichtfeld`;
      validators.push(checkRequired);
    }
  }

  if (formField.validation) {
    formField.validation.forEach(validator => {
      if (typeof validator === 'object' && validator.validator !== undefined) {
        const Validator = validator as Validator;
        const checkRequired = (v: any) => {
          return (
            Validator.validator(v) ||
            i18n.t('errorMessages.' + Validator.errorLabel)
          );
        };
        validators.push(checkRequired);
      }
      if (validator === ValidationType.numeric) {
        const checkNumber = (v: any) =>
          /^[0-9]+$/.test(v) || !v || `Nur nummerische Werte sind erlaubt`;
        validators.push(checkNumber);
      } else if (validator === ValidationType.phoneNumber) {
        const checkNumber = (v: any) =>
          /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\\./0-9]*$/.test(v) ||
          `Bitte geben Sie eine gültige Telefonnummer ein.`;
        validators.push(checkNumber);
      }
    });
  }
  return validators;
};
