










































































































import { defineComponent, ref } from '@vue/composition-api';
import { stepComponentProps } from '@/lib/steps/helper';
import productFinder from './productFinder';
import productData from './productData';
import AssistantStep from '@/components/AssistantStep.vue';
import AssistantStepNavigation from '@/components/AssistantStepNavigation.vue';
import GcDialog from '@/components/primitives/GcDialog.vue';
import GcForm from '@/components/primitives/GcForm.vue';
import { useBaseTransitions } from '@/lib/xstate/transitions';
import { formatPrice } from '@/lib/formatter/price';
import completedSteps from '../../lib/completed-steps';
import { getStepContext } from '@/lib/context';
import { StepDefinition } from '@/interfaces/step-definition';

export default defineComponent({
  components: {
    AssistantStep,
    AssistantStepNavigation,
    GcDialog,
    GcForm,
  },
  props: {
    ...stepComponentProps,
  },
  setup(props, { emit }) {
    const { onNext: onNextTransition, onPrev } = useBaseTransitions(emit);
    const infoModalOpen = ref(false);
    const selectedProduct = ref<unknown | null>(null);
    const siteUrl = process.env.VUE_APP_BASE_URL;
    const formData = getStepContext(
      props.assistantContext,
      props.step as StepDefinition,
    );

    const productKeys = productFinder(props.assistantContext);
    const products = productKeys.map(productKey =>
      productData(productKey, props.assistantContext),
    );
    const groups = [...new Set(products.map(product => product.group))];

    const productsByGroup = group =>
      products.filter(product => product.group === group);

    function showProductsDetails(product) {
      if (product) {
        selectedProduct.value = product;
        infoModalOpen.value = true;
      }
      return false;
    }

    const onNext = () => {
      formData.value.suggestedProducts = products;
      onNextTransition();
    };

    return {
      completedSteps,
      formatPrice,
      groups,
      infoModalOpen,
      onNext,
      onPrev,
      products,
      productsByGroup,
      selectedProduct,
      showProductsDetails,
      siteUrl,
    };
  },
});
