declare const dataLayer: Array<Record<string, unknown>>;
interface EventData {
  eventName: string;
  eventCategory: string;
  eventAction: string;
  eventLabel: string;
}
/* eslint-disable no-console */
export const sendEvent = (data: EventData) => {
  if (typeof dataLayer === 'undefined') {
    console.warn('DataLayer is not defined');
    return;
  }
  const { eventName, ...rest } = data;
  dataLayer.push({
    event: eventName,
    ...rest,
  });
};
/* eslint-enable no-console */
