import heating from '../steps/heating';
import household from '../steps/household';
import object from '../steps/object';
import misc from '../steps/misc';
import contact from '../steps/contact';
import products from '../steps/products';
import done from '../steps/done';

const steps = [heating, household, object, misc, products, contact, done];

export default steps;
